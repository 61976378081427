import { useIntl } from "gatsby-plugin-intl"
import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { schedule } from "../types/schedule"
import "./education_schedule.scss"

export default function EducationSchedule() {
  const intl = useIntl()
  const [percent, setPercent] = useState(0)

  const handleScroll = (e: any) => {
    const scrollTop = document.querySelector(".scroll-wrapper")?.scrollTop || 0
    const totalHeight =
      (document.querySelector(".scroll-wrapper")?.scrollHeight || 104) - 104
    const headerHeight = document?.documentElement?.querySelector(
      ".schedule-list-header"
    )?.clientHeight
    const footerHeight = document?.documentElement?.querySelector(
      ".schedule-list-footer"
    )?.clientHeight
    const listHeight = totalHeight - (headerHeight || 0) - (footerHeight || 0)

    const percent = (scrollTop / listHeight) * 100
    setPercent(Math.ceil(percent > 100 ? 100 : percent))
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [1])

  return (
    <>
      <Seo title={"Education Schedule"} />
      <main className="education-schedule-page">
        <div className="top-layer hidden md:flex">
          <div className="line" />
          <h1 className="ABC-Mono">
            {(percent || 0).toString().padStart(3, "0")}%
          </h1>
        </div>
        <div
          className="grid grid-cols-1 md:grid-cols-2 scroll-wrapper"
          onScroll={handleScroll}
        >
          <div className="hidden md:block"></div>
          <ul className="schedule-list">
            <li className="schedule-list-header PPG">
              {intl.formatMessage({ id: "education.schedule" })}
            </li>
            {schedule.map((item, index) => (
              <li key={item} className="flex md:gap-x-12 justify-start w-full">
                <span style={{ width: 45 }} className="ABC-Mono flex-shrink-0">
                  {(index + 1).toString().padStart(3, "0")}
                </span>
                <span className="schedule-item-content">{item}</span>
              </li>
            ))}
            <li className="schedule-list-footer hidden md:block"></li>
          </ul>
        </div>
      </main>
    </>
  )
}
